



body[data-theme="vendor"] {

    .auth-full-page-container {
        background-color: #C4D7C5;
    }

    .btn-primary {
        background-color: #388e3d;
        border-color: #388e3d;
    }
    
    .text-primary {
        color: #388e3d !important;
    }

    
        .vertical-menu {
            background: #31423b !important;
        }
    
        #sidebar-menu {
            background: #31423b !important;
            ul {
                li {
                    background: lighten(#31423b, 3%) !important;
                    a {
                        color: $sidebar-dark-menu-item-color;
    
                        i {
                            color: $sidebar-dark-menu-item-icon-color;
                        }
    
                        &:hover {
                            color: $sidebar-dark-menu-item-hover-color;
    
                            i {
                                color: $sidebar-dark-menu-item-hover-color;
                            }
                        }
                    }
    
                    ul.sub-menu {
                        li {
                            a {
                                color: $sidebar-dark-menu-sub-item-color;
    
                                &:hover {
                                    color: $sidebar-dark-menu-item-hover-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    
        // Enlarge menu
        &.vertical-collpsed {
            min-height: $layout-collapsed-min-height;
    
            // Side menu
            .vertical-menu {
                .navbar-brand-box {
                    background: #31423b !important;
                }
    
                // Sidebar Menu
                #sidebar-menu {
                    >ul {
    
                        >li {
                            &:hover {
                                >a {
                                    background: lighten(#31423b, 3%);
                                    color: $sidebar-dark-menu-item-hover-color;
    
                                    i {
                                        color: $sidebar-dark-menu-item-hover-color;
                                    }
                                }
    
                                >ul {
                                    a {
                                        color: $sidebar-dark-menu-sub-item-color;
    
                                        &:hover {
                                            color: $sidebar-dark-menu-item-hover-color;
                                        }
                                    }
                                }
                            }
                        }
    
                        ul {
                            background-color: #31423b !important;
                        }
                    }
    
                    ul {
                        li {
                            &.mm-active .active {
                                color: $sidebar-dark-menu-item-active-color !important;
    
                                i {
                                    color: $sidebar-dark-menu-item-active-color !important;
                                }
                            }
                        }
                    }
                }
            }
        }



.navbar-brand-box {
    background: #30423A;
}   
}


