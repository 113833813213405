// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
      background: transparent;
      color: rgba(123, 128, 154, 0.7);
  }

  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }


}


.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

.table-sticky {
  overflow-y: auto;
  max-height: 100%;

  thead th {
    position: sticky;
    top: 0px;
    z-index: 1;
    background: white;
  }
}

.table-responsive {

  td {
    color: 040811;
  }
  tr {
    vertical-align: middle;
  }
  tr:nth-child(odd) td {
      background-color: transparent !important;
  }
}
